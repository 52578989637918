<template>
<div @click="toggle()" :class="['burger-btn', { 'is-open': mobileNavOpen }]">
  <span />
</div>
</template>

<script>
export default {
  name: 'BurgerButton',
  data() {
    return {
      mobileNavOpen: false
    }
  },
  methods: {
    toggle() {
      this.mobileNavOpen = !this.mobileNavOpen
    },
    render() {
      if (this.mobileNavOpen) {
        document.documentElement.classList.add('menu')
      } else {
        document.documentElement.classList.remove('menu')
      }
    }
  },
  watch: {
    mobileNavOpen() {
      this.render()
    }
  }
}
</script>

<style scoped>
.burger-btn {
  @apply w-8 h-8 block relative cursor-pointer overflow-visible;

  & > span,
  &:before,
  &:after {
    @apply block w-full bg-white absolute;
    height: 3px;
    top: 50%;
    left: 0;
    transition: all .05s ease;
    content: '';
  }

  & > span {
    @nest .menu & {
      opacity: 0;
    }
  }

  &:before {
    margin-top: -10px;
    @nest .menu & {
      transform: translate(0, 10px) rotate(45deg);
    }
  }
  &:after {
    margin-top: 10px;
    @nest .menu & {
      transform: translate(0, -10px) rotate(-45deg);
    }
  }

}
</style>
