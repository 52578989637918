<template>
  <transition appear name="fade">
    <div class="bg-white fixed bottom-0 left-0 lg:flex border-b-2 border-brand-blue items-center justify-between w-full px-8 py-4 z-40" v-if="isOpen">
      <p class="u-p--small md:pb-2 lg:pb-0">Diese Website verwendet Cookies, um Ihnen die bestmögliche Funktionalität bieten zu können.</p>
      <div class="mo:mt-4 mo:flex mo:justify-between">
        <a href="/datenschutz" class="button">Weiterlesen</a>
        <button class="button ml-2" @click.prevent="closeLayer">OK</button>
      </div>
    </div>
  </transition>
</template>

<script>
import PermissionsHelper from '@/util/permissions'

export default {
  data () {
    return {
      isOpen: false,
    }
  },
  mounted() {
    if (!PermissionsHelper.cookiesApproved){
      this.isOpen = true
    }
  },
  methods: {
    closeLayer() {
      PermissionsHelper.cookiesApproved = true
      this.isOpen = false
    },
  }
}
</script>
