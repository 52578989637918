<template>
  <div class="flipcard w-full h-full relative" >
    <div class="w-full h-full flex flex-col justify-center items-center">
      <template v-if="content.copy">
        <slot></slot>
        <h2 class="pt-12 u-h2 uppercase pb-2">{{ content.headline }}</h2>
      </template>
      <template v-else>
        <a href="https://www.google.de" target="_blank">
          <slot></slot>
          <h2 class="pt-12 u-h2 uppercase pb-2">{{ content.headline }}</h2>
        </a>
      </template>
    </div>
    <div
      v-if="content.copy"
      class="backside overflow-auto w-full h-full flex justify-center absolute top-0 left-0 bg-brand-blue p-8 lg:p-12"
      :class="{ 'visible' : showBack }"
      @mouseover="!isMobile ? showBack = true : ''"
      @mouseout="!isMobile ? showBack = false : ''"
      @click="isMobile ? showBack = !showBack : ''"
    >
      <div class="rte u-p max-w-xl" v-html="content.copy"></div>
    </div>
  </div>
</template>

<script>
import isMobile from 'ismobilejs'

export default {
  props: {
    content: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      showBack: false
    }
  },
  computed: {
    isMobile() {
      return isMobile().any
    }
  },
}
</script>
<style>
.backside {
  transition: opacity 0.2s;
  opacity: 0;
}
.backside.visible {
  opacity: 1;
}
.flipcard svg {
  width: 110px;
  height: 110px;
}
</style>
