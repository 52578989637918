var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"maps w-full relative"},[_vm._m(0),_c('GmapMap',{ref:"gmap",staticClass:"rounded-xl sm:rounded-2xl w-full h-full relative",attrs:{"center":_vm.location,"zoom":15,"options":{
        zoomControl: true,
        scrollwheel: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        styles: _vm.mapStyle
      },"map-type-id":"roadmap"}},[_c('gmap-custom-marker',{attrs:{"marker":_vm.location}},[_c('div',{staticClass:"w-8 h-8 bg-brand-blue rounded-full map-maker"})])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"absolute z-10 transform-left flex flex-col text-center mt-8"},[_c('a',{attrs:{"href":"https://www.google.com/maps/dir//Zahnheilkunde+am+Marktplatz,+Beim+unteren+Tor,+M%C3%BCnsingen,+Deutschland/@48.4131918,9.4854942,15z/data=!4m9!4m8!1m0!1m5!1m1!1s0x479985d20d3a7ead:0xd8e7e8b69cbef246!2m2!1d9.4942492!2d48.4131971!3e0?hl=de-DE","target":"_blank"}},[_c('div',{staticClass:"button button--gray w-56"},[_vm._v("Routenplaner")])])])}]

export { render, staticRenderFns }