import Cookies from 'js-cookie'

class PermissionsHelper {
  constructor() {
    throw new Error('Cannot construct singleton')
  }

  static set cookiesApproved(value) {
    Cookies.set('ZAMCookiesApproved' , value, { expires: 3650 })
  }

  static get cookiesApproved() {
    return Cookies.get('ZAMCookiesApproved')
  }
}

export default PermissionsHelper
