import { throttle } from 'throttle-debounce';
const mqMobile = window.matchMedia('(max-width: 1080px)')

class Scroll {
  constructor () {
    this.prevScrollPos = null
    this.isMobile = false
    this._init()
  }

  _init() {
    window.addEventListener('scroll', throttle(250, () => this.onScroll()))

    this.isMobile = mqMobile.matches
    window.addEventListener('resize', () => {
      this.isMobile = mqMobile.matches
    })
  }
  onScroll() {
    if(this.isMobile) {
      return
    }
    const currentScrollPos = window.pageYOffset
    if(window.pageYOffset < 50) {
      document.body.classList.remove('hide')
    } else if(this.prevScrollPos > currentScrollPos) {
      document.body.classList.remove('hide')
    } else {
      document.body.classList.add('hide')
    }
    this.prevScrollPos = currentScrollPos
  }
}

export default new Scroll()
