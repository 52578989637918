<template>
  <aside
    v-if="isOpen"
    class="w-full h-full fixed inset-0 bg-overlay z-50 flex flex-col justify-center items-center"
  >
    <div
      class="job-overlay  mo:mx-6 px-6 py-8 sm:pb-8 sm:px-20 leading-normal sm:leading-relaxed text-center text-white bg-brand-blue flex-none relative"
      v-click-outside="close"
    >
      <h2 class="job-overlay__headline  with-underline  sm:whitespace-no-wrap mo:mx-4">
        {{ content.headline }}
      </h2>
      <div class="job-overlay__text   max-w-md mb-6" v-html="content.text"></div>
      <anchor-link to="jobs" class="uppercase button button--inverted" @anchor-click="close">
        Mehr Informationen
      </anchor-link>
      <button class="block w-4 h-4 mr-4 mt-4 absolute top-0 right-0" @click="close">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g fill="none" fill-rule="evenodd" stroke="#FFF" stroke-linecap="square" stroke-width="2" transform="translate(2 2)">
            <line x2="12" y2="12"/>
            <line x2="12" y1="12"/>
          </g>
        </svg>
      </button>
    </div>
  </aside>
</template>

<script>
export default {
  props: {
    content: {
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      isOpen: true,
    }
  },
  mounted() {
    document.querySelector('html').classList.add('freeze')
  },
  methods: {
    close() {
      this.isOpen = false
      document.querySelector('html').classList.remove('freeze')
    }
  }
}
</script>

<style>
@import '../style/utilities/utilities.css';

.job-overlay {
  max-width: 31rem;
}

.job-overlay__headline {
  @apply font-suisse-medium tracking-widest uppercase;
  font-size: 20px;
}

.job-overlay__text {
  @apply font-suisse-medium;
  font-size: 14px;
  letter-spacing: 0.07em;
  line-height: 1.75;
}

@screen sm {
  .job-overlay__text {
    font-size: 14px;
  }
}
</style>
