import './util/polyfills'
import 'lazysizes'
import 'lazysizes/plugins/bgset/ls.bgset.min'
import 'lazysizes/plugins/parent-fit/ls.parent-fit.min'
import 'lazysizes/plugins/attrchange/ls.attrchange.min'

import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import Scroll from './components/Scroll'
import vhCheck from 'vh-check'
import "./components"
import './style/app.css'
import './util/svg-imports'
import 'badger-accordion/dist/badger-accordion.css'

import AnchorLink from './components/AnchorLink'
import BurgerButton from './components/BurgerButton'
import CookieConsent from './components/CookieConsent'
import Philosophy from './components/Philosophy'
import GoogleMap from './components/GoogleMap'
import SwiperSlider from './components/SwiperSlider'
import FlipCard from './components/FlipCard'
import ImageFlipCard from './components/ImageFlipCard'
import JobOverlay from './components/JobOverlay'
import {BadgerAccordion, BadgerAccordionItem} from 'vue-badger-accordion'
import vClickOutside from 'v-click-outside'

Vue.config.productionTip = false
Vue.component('Scroll', Scroll)
Vue.component('AnchorLink', AnchorLink)
Vue.component('SwiperSlider', SwiperSlider)
Vue.component('CookieConsent', CookieConsent)
Vue.component('GoogleMap', GoogleMap)
Vue.component('FlipCard', FlipCard)
Vue.component('ImageFlipCard', ImageFlipCard)
Vue.component('BurgerButton', BurgerButton)
Vue.component('BadgerAccordion', BadgerAccordion)
Vue.component('BadgerAccordionItem', BadgerAccordionItem)
Vue.component('Philosophy', Philosophy)
Vue.component('JobOverlay', JobOverlay)

Vue.use(vClickOutside)
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAPS_KEY,
    v: '3.36'
  }
})

new Vue({
  el: "#hml-root",
  delimiters: ["${", "}"]
});

vhCheck()
