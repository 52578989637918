<template>
  <a :href="'#' + to" @click.prevent="onClick">
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      $target: null,
      pageHasTarget: false
    }
  },
  methods: {
    scrollToTarget() {
      const offset = this.$target.offsetTop > window.scrollY ? 50 : 100
      window.scrollTo({ top: this.$target.offsetTop - offset, behavior: 'smooth' })
    },
    onClick() {
      document.documentElement.classList.remove('menu')
      this.scrollToTarget()
      this.$emit('anchor-click')
    }
  },
  mounted() {
    this.$target = document.querySelector(`a[name='${this.to}']`)
  }
}
</script>
