<template>
  <div class="swiper-container">
    <slot name="before-wrapper" />
    <div class="swiper-wrapper">
      <slot />
    </div>
    <slot name="after-wrapper" />
    <div class="indicator">{{ indicator }}</div>
  </div>
</template>

<script>
import 'swiper/css/swiper.min.css'
import { Navigation, Autoplay, Pagination, Swiper } from 'swiper/js/swiper.esm.js'

Swiper.use([Pagination, Autoplay, Navigation])

export default {
  props: {
    options: { type: Object, default: null },
  },
  data() {
    return {
      swiper: null,
      slidesTotal: 0
    }
  },
  watch: {
    options() {
      this.recreateSwiper()
    },
  },
  mounted() {
    this.recreateSwiper()
  },

  updated() {
    this.swiper.update()
  },

  beforeDestroy() {
    if (this.swiper) this.swiper.destroy()
  },
  computed: {
    indicator() {
      if (!this.swiper) {
        return '0/0'
      }
      const a = this.swiper.realIndex+1 < 10 ? '0' : ''
      const b = this.slidesTotal < 10 ? '0' : ''
      return `${a}` + (this.swiper.realIndex+1) + ' / ' + `${b}` + this.slidesTotal
    }
  },
  methods: {
    control(swiper) {
      this.controlled = swiper
      this.recreateSwiper()
    },

    recreateSwiper() {
      if (this.swiper) this.swiper.destroy()

      const o = Object.assign({}, {
        autoplay: true,
        loop: true,
        speed: 500,
        preloadImages: false,
        centeredSlides: true,
        lazy: true,
        slidesPerView: 'auto',
        spaceBetween: 8,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
      },
      this.options,
      {
        controller: {
          control: this.controlled,
        },
      })

      this.swiper = new Swiper(this.$el, o)
      this.slidesTotal = (this.swiper.activeIndex ) % (this.swiper.slides.length - 1)
      this.$emit('update:swiper', this.swiper)
    },
  },
}
</script>

<style>
.swiper-container .indicator {
  @apply text-brand-blue font-suisse-medium text-base absolute z-10 text-center w-full;
  bottom: -3rem;
  letter-spacing: 0.15rem;
}
@screen md {
  .swiper-container .indicator {
    bottom: -3.5rem;
  }
}
</style>
