<template>
  <div class="w-full mt-8 md:mt-12 lg:mt-0">
    <template v-if="isMobile">
      <div class="lg:hidden px-6 md:px-16 mb-12 md:mb-24 flex flex-wrap">
        <dl is="BadgerAccordion" :icons="false" ref="acc">
          <template
            v-for="(accItem,index) in items">
            <div is="BadgerAccordionItem">
              <h2 class="uppercase u-h2 py-3 mr-5 select-none" slot="header">{{ accItem.headline }}</h2>
              <div slot="content">
                <div class="rte pb-5 pt-1 u-p" v-html="accItem.copy"></div>
              </div>
            </div>
          </template>
          </dl>
      </div>
    </template>
    <template v-else>
      <div class="flex px-16 pb-20 pt-24 md:-ml-2">
        <div class="w-6/12 flex flex-wrap">
          <div
            class="menu-item w-1/2 cursor-pointer uppercase u-p pb-10"
            :class="{'dotted': currentIndex == index, '-mx-8': index % 2 != 0}"
            v-for="(item, index) in items"
            @mouseover="currentIndex = index"
            :key="index">
            <h2 class="uppercase u-h2">{{ item.headline }}</h2>
          </div>
        </div>
        <div class="w-6/12">
          <transition name="fade" mode="out-in">
            <div
              class="pl-16 h-48 u-p max-w-xl "
              v-for="(item, index) in items"
              :key="index"
              v-if="currentIndex === index"
              v-html="item.copy">
            </div>
          </transition>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
const mqMobile = window.matchMedia('(max-width: 1023px)')

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {
      currentIndex: 0,
      isMobile: false
    }
  },
  mounted () {
    this.isMobile = mqMobile.matches

    if (this.isMobile) {
      setTimeout(() => {
        this.$refs.acc.open(0)
      }, 500)
    }

    window.addEventListener('resize', () => {
      this.isMobile = mqMobile.matches
      console.log(this.isMobile,this.$refs, this.$refs.acc)
    })
  }
}
</script>
<style>
.dotted {
  @apply relative;
}
.dotted:before {
  @apply bg-brand-gray absolute top-0 rounded-full;
  left: -15px;
  content: '';
  width: 5px;
  margin-top: 12px;
  height: 5px;
}
.philosophy-copy {
  max-width: 42rem !important;
}
</style>
