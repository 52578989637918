<template>
  <nav class="text-white w-full">
    <ul class="flex flex-wrap menu:text-center">
      <li class="w-full menu-above:w-1/3 mb-10" :class="{ 'is-active': activeSection == 'leistungen' }">
        <anchor-link class="uppercase link" to="leistungen">Leistungen</anchor-link>
      </li>
      <li class="w-full menu-above:w-1/3 mb-10" :class="{ 'is-active': activeSection == 'gallery' }">
        <anchor-link class="uppercase link" to="gallery">Praxis</anchor-link>
      </li>
      <li class="w-full menu-above:w-1/3 mb-10" :class="{ 'is-active': activeSection == 'team' }">
        <anchor-link class="uppercase link" to="team">Team</anchor-link>
      </li>
      <li class="w-full menu-above:w-1/3 menu:mb-10" :class="{ 'is-active': activeSection == 'service' }">
        <anchor-link class="uppercase link" to="service">Service</anchor-link>
      </li>
      <li class="w-full menu-above:w-1/3 menu:mb-10" :class="{ 'is-active': activeSection == 'jobs' }">
        <anchor-link class="uppercase link" to="jobs">Jobs</anchor-link>
      </li>
      <li class="w-full menu-above:w-1/3" :class="{ 'is-active': activeSection == 'kontakt' }">
        <anchor-link class="uppercase link" to="kontakt">Kontakt</anchor-link>
      </li>
    </ul>
  </nav>
</template>

<script>

export default {
  data() {
    return {
      activeSection: 'intro'
    }
  },
  methods: {
    onSectionChange(changes) {
      let highestRatio = 0
      let mostChanged = null
      for (let i = 0; i < changes.length; i++) {
        let change = changes[i]
        if (
          change.intersectionRatio >= 0.5 &&
          highestRatio < change.intersectionRatio
        ) {
          highestRatio = change.intersectionRatio
          mostChanged = change
        }
      }
      if (mostChanged) {
        this.activeSection = mostChanged.target.id
      }
    }
  },
  created() {
    // this.observer = new IntersectionObserver(this.onSectionChange, {
    //   root: null,
    //   threshold: 0.5
    // })
  },
  mounted() {
    // const sections = document.querySelectorAll('[data-section]')
    // for (let i = 0; i < sections.length; i++) {
    //   this.observer.observe(sections[i])
    // }
  }
}
</script>

<style>
.navigation {
  @apply w-4/12 h-full bg-brand-gray pr-12 ml-1 px-4 py-2;
}

.navigation li.is-active {
  @apply text-brand-blue;
}

@screen menu{
  .navigation {
    @apply hidden w-full ml-0 px-0 overflow-scroll;
    margin-top: -95px;
    font-size: 18px;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
  }
  .navigation nav {
    @apply flex items-center justify-center;
  }
}

html.menu .navigation {
  display: flex;
}


</style>
