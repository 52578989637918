<template>
  <div class="flipcard w-full h-full relative"
       @click.prevent="showBack = !showBack">
    <div
      class="w-full h-full">
      <div class="relative h-auto">
        <slot></slot>
        <div
          v-if="content.copy"
          class="backside overflow-auto w-full h-full absolute top-0 left-0 bg-brand-yellow px-6 py-8 lg:p-12"
          :class="{ 'visible' : showBack }"
        >
          <div class="rte u-p max-w-xl pointer-events-none" v-html="content.copy"></div>
        </div>
      </div>
      <div class="px-6 lg:px-12 py-4 md:pt-8 md:pb-6 flex">
        <div>
          <h1 class="name u-p--wide uppercase">{{ content.name }}</h1>
          <p class="u-p--wide">{{ content.position }}</p>
        </div>
        <div
          v-if="content.copy"
          class="toggleindicator ml-auto cursor-pointer">
          <template v-if="showBack">
            <Minus />
          </template>
          <template v-else>
            <Plus />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Plus from '@/assets/svg/plus_icon_gray.svg?inline'
import Minus  from '@/assets/svg/minus_icon_gray.svg?inline'
export default {
  props: {
    content: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  components: {
    Plus,
    Minus
  },
  data() {
    return {
      showBack: false
    }
  },
}
</script>
<style>
.backside {
  transition: opacity 0.2s;
  opacity: 0;
  width: 101% !important;
}
.backside.visible {
  opacity: 1;
}
.flipcard svg {
  width: 110px;
  height: 110px;
}

.flipcard .toggleindicator {
  @apply rounded-full bg-brand-gray text-brand-yellow;
  height: 26px;
  width: 26px;
  text-align: center;
}

</style>
